<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from './components/popup'
import { mapState } from 'vuex'
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(['loading'])
  }
}
</script>

<style lang="less">
@import './assets/css/public.less';
body {
  background: #F9F9F9;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #333333;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #343434;
  font-size: 32px;
  border-radius: 20px;
}
.downloadAppBox {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px #636363;
  z-index: 1;
  &-cancel {
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      margin: 0 20px;
      border-radius: 10px;
    }
  }
  &-btn{
    padding:0 20PX;
    margin-right: 20px;
  }
}

</style>
