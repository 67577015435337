export default {
  home: {
    minute: "minuto",
    price_info:
      "Los primeros {mianfei} minutos son gratuitos. El cargo máximo por 24 horas es de {currencyName}{fengding}. Tu depósito de {currencyName}{yajin} se devolverá automáticamente después de devolver la batería externa",
    feature_1: "Incluye todos los tipos de cables: iPhone, Tipo-C y Micro-USB",
    feature_2: "Se puede devolver en cualquier estación",
    feature_3:
      "Carga inalámbrica disponible",
    tips_1:
      "Tu depósito de {currencyName}{yajin} se devolverá al final del alquiler",
    // tips_1: 'A deposit of {currencyName}{yajin} needs to be recharged before renting if the balance is insufficient',
    tips_2:
      "El saldo actual es de {currencyName}{yajin}, que se puede usar directamente para el alquiler",
    tips_3:
      "The current balance is {currencyName}{myyue}, which can be used for rental directly",
    submit: "Comenzar alquiler",
    borrow: "Alquilar",
    msg_fail_title: "Error",
    msg_fail_content: "El alquiler falló, por favor inténtalo de nuevo",
    msg_rentcount: "Actualmente tienes {zujieNum} pedidos que no están cerrados",
    goborrow: "¡Ve a cargar!!",
    nearby: "Ver estaciones cercanas",
    preauth: "Pre-autorización"
  },
  login: {
    code_title: "Verificación vía SMS",
    phone_number: "Número de teléfono",
    verification_code: "Por favor ingresa el código de verificación de SMS",
    captcha_code: "Código",
    send_code: "Enviar Códig",
    login: "Iniciar Sesión",
    agree: "Al hacer clic en Iniciar Sesión, aceptas ",
    readme: "Acuerdo de Usuario",
    msg_fail_login: "Inicio de Sesión Fallido",
    msg_success_login: "Inicio de Sesión Exitoso",
    msg_fail_user: "Error al obtener la información del usuario. ¿Quieres intentarlo de nuevo?",
    sign_in_with: "Iniciar sesión con",
    no_sms: "No recibí el mensaje de texto",
    retry: "Reintentar"
  },
  confirm: {
    deletecard: "Eliminar Tarjeta",
    addcard: "Añadir los Detalles de la Tarjeta",
    title: "Confirmación de Pago",
    paynow: "Pago Inmediatot",
    SMSConfirm:
      "Por favor paga según el mensaje de texto recibido en tu teléfono y haz clic en el botón de confirmar para continuar.",
    pay_label: "Monto",
    auth_label: "Pre-autorización",
    price_tips:
      "Tu saldo se puede retirar si lo solicitas. Puede tardar de 1 a 5 días hábiles en aparecer en tu cuenta de pago.",
    auth_tips:
      "Se requiere un monto de pre-autorización de {0}{1}. El monto real del alquiler se deducirá cuando se devuelva la batería externa.",
    pay_type: "Por favor selecciona tu método de pago preferido",
    submit: "Confirmar Pago",
    msg_loading: "Cargando....",
    msg_fail_title: "Advertenciag",
    msg_fail_payment: "No se pudo iniciar el pago del depósito",
    msg_fail_order: "No se pudo crear un pedido de alquiler",
    msg_fail_support: "El sistema no es compatible",
    msg_fail_confirm: "Confirmar",
    msg_fail_retry: "Reintentar",
    msg_fail_cancel: "Cancelar"
  },
  mine: {
    title: "Perfil de Usuario",
    wallet: "Mi Cartera",
    balance: "Mi Saldo",
    deposit: "Depósito",
    wallet_in: "Recargar",
    wallet_out: "Retirar",
    wallet_log: "Registro de Cartera",
    order: "Pedido",
    coupon: "Cupón",
    login_tips: "Por favor inicia sesión",
    msg_fail_title: "Error",
    msg_fail_update: "La modificación falló. Por favor inténtalo de nuevo",
    msg_loading: "Cargando...",
    msg_fail_user: "Error al obtener la información del usuario. ¿Quieres intentarlo de nuevo?",
    help: "Ayuda",
    setting: "Ajuste",
    logout: "Cerrar Sesión",
    buy: "Comprar Equipo"
  },
  msg: {
    deletecard: "¿Estás seguro de que quieres eliminar esta tarjeta?",
    no_phone: "Se requiere un número de teléfono",
    loading: "Cargando...",
    title: "Consejot",
    success: "Operación Exitosa",
    error: "Error Desconocido. Por favor inténtalo de nuevo más tarde",
    roleerror: "Permisos Insuficientes. Por favor consulta al administrador.",
    neterror: "La conexión de red falló. Por favor inténtalo de nuevo más tarde",
    confirm: "Confirmar",
    retry: "Reintentar",
    cancel: "Cancelar",
    title_fail: "Advertencia",
    vieworder: "Ver Pedido",
    success_payment: "Pago Completado",
    success_buy: "Compra Completada",
    fail_payment: "Fallo en el Pago",
    keyword: "Palabra Clave",
    ispayorder: "Por favor confirma si se ha realizado el pago",
    unpay: "No Pagado",
    yespay: "Pagado",
    notopen: "Aún no Abierto"
  },
  result: {
    tips:
      "Para ver pedidos y otras ubicaciones de estaciones, por favor descarga la aplicación",
    download: "Descargar",
    title: "Alquiler Confirmado",
    gotit: "Entendido",
    price_plan: "Tarifa Estándar",
    msg_success: "Alquilado con éxito",
    msg_tips_loading:
      "La batería externa se está expulsando, por favor espera...",
    msg_result_loading: "Se está confirmando el resultado del pago...",
    msg_fail_confirm: "Confirmar",
    btn_usage: "Nota de Uso",
    btn_return: "Nota de Devolución",
    deposit: "Depósito",
    msg_fail_content: "La solicitud falló. Por favor inténtalo de nuevo.",
    price_label: "por hora",
    price_info:
      "Los primeros {mianfei} minutos son gratuitos. El cargo máximo por 24 horas es de {currencyName}{fengding}. Tu depósito de {currencyName}{yajin} se devolverá automáticamente después de devolver la batería externa",
    tips_1:
      "Por favor recoje la batería externa No.{number} como se muestra en la imagen",
    tips_2:
      "Por favor organiza los cables e inserta la batería externa en la ranura con el lado marcado con [flechas hacia abajo]",
    msg_note_usage:
      "La batería externa está equipado con 3 tipos de cables, por favor elige cual necesitas para tu dispositivo.",
    msg_note_return:
      "Por favor organiza los cables e inserta la batería externa en la ranura con el lado marcado con [flechas hacia abajo]",
    return_info:
      "Al devolverla, asegúrate de que los cables estén ordenados e inserta el lado con chips metálicos en un enchufe disponible hasta que llegue al fondo y se estabilice."
  },
  result_msg: {
    loading: "Cargando...",
    refresh: "Actualizar"
  },
  order: {
    title: "Pedido de Alquiler",
    in_use: "En Uso",
    ended: "Terminado",
    msg_error: "Solicitud fallida"
  },
  order_item: {
    order_number: "No.",
    venue_rented: "Ubicación de Recogida",
    time_rented: "Hora de Recogida",
    venue_returned: "Ubicación de Devolución",
    time_returned: "Hora de Devolución",
    charge_standard: "Tarifa estándar",
    charge_standard_info:
      "{pJifei} {pCurrency} por hora (primeros {pMian} minutos gratis)",
    rent_occurred: "Alquiler",
    returned: "Devuelto",
    unreturned: "No Devuelto",
    type_0: "No Completado",
    type_1: "Alquilando",
    type_2: "Cancelado",
    type_3: "Devuelto",
    type_4: "Error ocurrido",
    type_5: "Tiempo extra",
    min: "Min",
    payment: "Pago"
  },
  setting: {
    title: "Ajuste",
    lang: "Idioma"
  },
  help: {
    title: "Preguntas Frecuentes"
  },
  wallet_in: {
    title: "Recarga de Saldo",
    price_label: "Monto de Recarga",
    price_placeholder: "Por favor ingresa el monto de recarga",
    balance: "Saldo Actual",
    price_auto: "Seleccionar Monto de Pago",
    price_tips:
      "Tu saldo se puede retirar bajo solicitud. Puede tardar de 1 a 5 días hábiles en aparecer en tu cuenta de pago.",
    submit: "Enviar",
    loading: "Cargando...",
    paying: "Redireccionando a la página de pago..",
    msg_title: "Consejos",
    msg_fail_order: "Error al crear la orden de recarga",
    msg_fail_payment: "Error al iniciar el pago de recarga",
    msg_fail_retry: "Reintentar"
  },
  wallet_out: {
    title: "Retiro",
    price_label: "Monto de Retiro",
    price_placeholder: "Por favor ingresa el monto de retiro",
    balance: "Saldo Actual",
    balance_all: "Retirar Todo",
    deposit: "Depósito",
    deposit_tips:
      "Hay un pedido de alquiler pendiente. El depósito de Ksh{pyajin} no es reembolsable",
    submit: "Enviar",
    apply: "Aplicar",
    confirm: "Confirmar",
    loading: "Cargando...",
    msg_fail_price: "El monto de retiro es incorrecto",
    msg_fail_balance:
      "El monto de retiro no puede exceder el saldo actual",
    msg_fail_submit: "Por favor completa toda la información requerida",
    msg_title: "Error",
    msg_success_content: "Reembolso Exitoso",
    confirmtitle: "Consejos",
    confirmcontent:
      "La cartera actual no admite retiros en efectivo. Por favor registra tu información de contacto para el procesamiento de reembolso manual",
    payee_name: "Nombre del Beneficiario",
    payee_bankcard: "Número de Tarjeta Bancaria",
    payee_phone: "Teléfono",
    cancelText: "Cancelar",
    confirmText: "Volver a Aplicar"
  },
  map: {
    在线: "En Línea",
    离线: "Fuera de Línea",
    title: "Estación Cercana",
    result_msg: "No se encontró ninguna estación cercana",
    msg_title: "error",
    no_support: "¡Lo sentimos! Tu navegador no soporta servicios de ubicación",
    my_position: "Mi Ubicación",
    distance: "Distancia:",
    shop_time: "Horas",
    free: "Disponible para Alquilar",
    return: "Ranuras vacías disponibles",
    shop_title: "Detalles del Local",
    address: "Dirección",
    go_here: "Ir aquí"
  }
};
